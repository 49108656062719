import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Sidebar from './sideBar';

function Home() {
    const [name, setName] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [socialMediaProfiles, setSocialMediaProfiles] = useState('');
    const [focusArea, setFocusArea] = useState('');
    const [ultimateBusinessVision, setUltimateBusinessVision] = useState('');
    const [specificBusinessProblems, setSpecificBusinessProblems] = useState('');
    const [customerResults, setCustomerResults] = useState('');
    const [productsOrServices, setProductsOrServices] = useState('');
    const [idealClientProfiles, setIdealClientProfiles] = useState('');
    const [leadMagnetsUsed, setLeadMagnetsUsed] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [mainContentThemes, setMainContentThemes] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const currentStep = 1;

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadedFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation: At least one of businessDescription or websiteUrl must be provided
        if (!businessDescription && !websiteUrl) {
            setError("Please provide either a business description or a website URL.");
            return; // Prevent form submission
        }

        try {
            const data = {
                name,
                business_description: businessDescription,
                website_url: websiteUrl,
                ultimate_business_vision: ultimateBusinessVision,
                specific_business_problems: specificBusinessProblems,
                customer_results: customerResults,
                products_or_services: productsOrServices,
                ideal_client_profiles: idealClientProfiles,
                lead_magnets_used: leadMagnetsUsed,
                main_content_themes: mainContentThemes,
                social_media_profiles: socialMediaProfiles,
                focus_area: focusArea,
            };

            const response = await fetch('https://glowing-invention-backend-755833753464.us-east1.run.app/api/home', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            const sessionId = responseData.session_id;
            localStorage.setItem('sessionId', sessionId);
            navigate('/baps');
        } catch (error) {
            console.log(error);
            setError("An error occurred while submitting the form. Please try again.");
        }
    };

    return (
        <>
            <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <div>
                <Sidebar currentStep={currentStep} />
            </div>

            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <div className="mb-4">
                        <div>
                            <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white pl-5">
                                Welcome To Your <span className="bg-[#FFFF00]">Marketing Magic</span> Journey!
                            </h1>
                            <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400 pl-5">
                                Let's discover how you can leverage your knowledge efficiently!
                            </p>

                            {error && (
                                <div className="error-message">
                                    <p>{error}</p>
                                </div>
                            )}

                            <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
                                <br />
                                <div className="mb-5">
                                    <label htmlFor="user_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        What's your name?
                                    </label>
                                    <input
                                        type="text"
                                        id="user_name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder='Mike'
                                        required
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="business_description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Describe your business in a few sentences.
                                    </label>
                                    <textarea
                                        id="business_description"
                                        value={businessDescription}
                                        rows="6"
                                        onChange={(e) => setBusinessDescription(e.target.value)}
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder='Mike’s business revolves around empowering bakery franchise owners who feel trapped in the daily grind of operations. Through transformative coaching and practical strategies, he helps them shift from an operator mindset to an owner mindset. This transition enables them to regain freedom, optimize business systems, and achieve sustainable success while living the life they originally envisioned when starting their business.'
                                    ></textarea>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="ultimate_business_vision" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        What is your ultimate business vision for the next 3 months? What specific goals do you want to achieve?
                                    </label>
                                    <textarea
                                        id="ultimate_business_vision"
                                        value={ultimateBusinessVision}
                                        onChange={(e) => setUltimateBusinessVision(e.target.value)}
                                        rows="6"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder='Mike aims to transform the lives of bakery franchise owners by helping at least 50 clients implement systems that allow their businesses to run autonomously. The short-term goal includes launching a flagship coaching program supported by the book, "Don’t Grow Until You’re Ready," which serves as a blueprint for creating scalable, owner-led businesses. He also envisions creating measurable impact by reducing burnout rates and increasing profitability for his clients by 15%.'
                                    ></textarea>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="specific_business_problems" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        What specific problems do your clients typically face that your business helps solve?
                                    </label>
                                    <textarea
                                        id="specific_business_problems"
                                        value={specificBusinessProblems}
                                        rows="6"
                                        onChange={(e) => setSpecificBusinessProblems(e.target.value)}
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder='Clients often feel enslaved to their businesses, struggling with endless operational tasks, lack of delegation, and fear of losing control. Many haven’t taken a vacation in years and are overwhelmed by reactive firefighting instead of proactive planning. They grapple with stagnant growth, unclear procedures, and unscalable practices. Mike’s coaching solves these problems by introducing systematization, delegation, and a shift to strategic ownership.'
                                    ></textarea>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="customer_results" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        What kind of results do your customers typically experience after working with you?
                                    </label>
                                    <textarea
                                        id="customer_results"
                                        value={customerResults}
                                        rows="6"
                                        onChange={(e) => setCustomerResults(e.target.value)}
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder='Mike’s clients experience a profound transformation: they gain the freedom to step away from daily operations, see consistent growth in revenue and profitability, and regain their passion for life and business. They report enhanced team empowerment, clarity in decision-making, and the ability to take vacations without business disruptions—all hallmarks of operating with an owner mindset.'
                                    ></textarea>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="products_or_services" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        What are the main products or services you offer, and what are their prices? Which one do you want to sell with this book?
                                    </label>
                                    <textarea
                                        id="products_or_services"
                                        value={productsOrServices}
                                        onChange={(e) => setProductsOrServices(e.target.value)}
                                        rows="6"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                                         placeholder={`Main offerings include:

// - Owner Transition Coaching Program: Comprehensive guidance on shifting from operator to owner.
// - Systemization Workshops: Hands-on training to build automated business systems.
// - Exclusive Mastermind Groups: Peer-driven insights for continued growth and scaling.
    
// For the book, the focus will be on promoting the Owner Transition Coaching Program, priced at $5,000, as a follow-up solution for implementing the book's principles.`}
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="ideal_client_profiles" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        In your opinion/experience, who are your ideal clients? Please provide detailed customer profiles if available.
                                    </label>
                                    <textarea
                                        id="ideal_client_profiles"
                                        value={idealClientProfiles}
                                        rows="6"
                                        onChange={(e) => setIdealClientProfiles(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                                         placeholder={`Ideal clients are bakery franchise owners earning six-figure revenues but feeling enslaved to their business. They are typically:

// - Aged 35-55, passionate about their craft but frustrated by operational chaos.
// - Overworked, experiencing family strain, and fearful of losing their business.
// - Highly driven but lack systems to scale effectively.
// - Open to coaching and ready for transformational change.`}
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="lead_magnets_used" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        What lead magnets have you used that work well or what are you considering to attract your ideal clients with?
                                    </label>
                                    <textarea
                                        id="lead_magnets_used"
                                        value={leadMagnetsUsed}
                                        rows="6"
                                        onChange={(e) => setLeadMagnetsUsed(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                                         placeholder={`Lead magnets include:

// - A free “Owner vs. Operator Mindset Evaluation” quiz.
// - Downloadable “Business Systemization Checklist.”
// - Webinar series on “Escaping the Operational Grind.”
// - Targeted email sequences to nurture leads generated through ads and partnerships.`}
                                    />
                                </div>


                                <div className="mb-5">
                                    <label htmlFor="main_content_themes" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Describe any book or major content pieces you have created or plan to create. What are the main themes or topics? Which one is the one you are wanting to focus on right now?
                                    </label>
                                    <textarea
                                        id="main_content_themes"
                                        value={mainContentThemes}
                                        rows="6"
                                        onChange={(e) => setMainContentThemes(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder='Mike’s book, Don’t Grow Until You’re Ready, is the centerpiece of his content strategy. It focuses on the critical mindset shift from operator to owner, empowering franchisees to build scalable, self-sufficient businesses. Themes include systematization, delegation, leadership development, and financial stability. Current efforts center on using this book to drive enrollment in his coaching programs.'
                                    />
                                </div>

								<div className="flex items-center justify-center w-full">
								<label
								htmlFor="dropzone-file"
								className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
								>
								{uploadedFile ? (
								<div className="flex flex-col items-center justify-center pt-5 pb-6">
								<svg
								className="w-8 h-8 mb-4 text-green-500 dark:text-green-400"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
								>
								<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M5 13l4 4L19 7"
								></path>
								</svg>
								<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
								Uploaded: <span className="font-semibold">{uploadedFile.name}</span>
								</p>
								<p className="text-xs text-gray-500 dark:text-gray-400">
								{(uploadedFile.size / 1024).toFixed(2)} KB
								</p>
								<button
								onClick={(e) => {
								e.preventDefault();
								setUploadedFile(null); // Remove the file without sending it to the backend
								}}
								className="mt-2 text-sm text-red-500 hover:text-red-700"
								>
								Remove file
								</button>
								</div>
								) : (
								<div className="flex flex-col items-center justify-center pt-5 pb-6">
								<svg
								className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 20 16"
								>
								<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
								/>
								</svg>
								<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
								<span className="font-semibold">Click to upload</span> or drag and drop
								</p>
								<p className="text-xs text-gray-500 dark:text-gray-400">Any</p>
								</div>
								)}
								<input
								id="dropzone-file"
								type="file"
								className="hidden"
								onChange={handleFileChange} // Handle file selection without sending it to the backend
								/>
								</label>
								</div>

                                <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">
                                    A document that could help us better understand your world
                                </div>

                                <br />

                                <div className="mb-5">
                                    <label
                                        htmlFor="website_url"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        What is your website domain and social media profiles in regard to us understanding your business better? Which one has more engagement?
                                    </label>

                                    <textarea
                                        id="website_URL"
                                        value={websiteUrl}
                                        rows="1"
                                        onChange={(e) => setWebsiteUrl(e.target.value)}
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="https://rediminds.com/"
                                    ></textarea>

                                    <textarea
                                        value={socialMediaProfiles}
                                        rows="1"
                                        onChange={(e) => setSocialMediaProfiles(e.target.value)}
                                        className="block p-2.5 w-full mt-4 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="List your active social media links (e.g., LinkedIn, Instagram)"
                                    ></textarea>

                                    {/* <textarea
                                        value={focusArea}
                                        rows="1"
                                        onChange={(e) => setFocusArea(e.target.value)}
                                        className="block p-2.5 w-full mt-4 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        // placeholder="Explain the key content or focus areas (e.g., sign-ups, resources)"
                                    ></textarea> */}
                                </div>

                                {error && (
                                    <div className="mb-4 text-red-600 font-semibold bg-white border-l-4 border-red-600 p-2 shadow-md">{error}</div>
                                )}

                                <div className="flex justify-end w-full mt-6 mb-2">
                                    <button
                                        type="submit"
                                        className="text-gray-900 bg-[#FFFF00] hover:bg-yellow-200 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-8 py-2.5 text-center"
                                    >
                                        Hit the Button and Watch the Magic Begin!
                                    </button>
                                </div>
                                <div className="mt-5 text-sm text-gray-500 dark:text-gray-300 text-center italic">
                                Your blueprint to intent-based branding is just one click away. Let us take over the heavy lifting so you can focus on what you do best: growing your business. Sit back, sip your coffee, and watch as your vision transforms into reality—fast, seamless, and with precision. 
                                <br></br>
                                <span className="block mt-4 not-italic font-bold">Start Your Journey Now!</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;